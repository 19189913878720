body {
  background-color: #ffffff !important;
  background-image: none;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
}
.admin-bg{
  padding: 0px;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
}
.navbar-light{
  box-shadow: none !important;
}
main{
  padding: 0px;
  background-color: #ffffff !important;
  margin-top: 60px;
}
.maincont{
  margin-left: 70px;
}
.main-cont-header{
  padding: 30px 30px 0 30px;
  position: relative;
  margin-top: 95px;
}
.main-cont-header .page-title{
  font-size: 14px;
  font-weight: 600;
}
.main-cont-header .dashoard-nav{
  font-size: 14px;
  font-weight: 600;
  color: #9CA3AF;
}
.main-cont-header .calender input.form-control{
  padding-left: 0px;
}
.header-actions{
  font-size: 14px;
  font-weight: 600;
  color: #6B7280;
}
.header-actions img {
  padding-right: 10px;
}
.main-cont-header .dashoard-nav a{
  color: #00976D;
  text-decoration: none;
}
.main-cont-header input, .main-cont-header select  {
  height: 40px !important;
}
.main-cont-header .input-group-text{
  background-color: transparent;
  border-right: 0px;
}
.main-cont-header .form-control, .main-cont-header .form-select{
  border-radius: 4px !important;
  outline: none !important;
  height: 40px !important;
  /* color: gray !important; */
}
 .main-cont-header .filter .input-group-text{
  border: 0px !important;
}
.last-sync{
  font-size: 12px;
  color: #6B7280;
  font-weight: 600 !important;
  padding-right: 40px !important;
  line-height: 35px;
}
.last-sync img{
  padding-right: 10px;
}
.last-sync span{
  font-weight: 600 !important;
}
.last-sync span.time-summery{
  font-weight: 400 !important;
  padding-left: 10px;
}

.dropdown{
  font-weight: 400;
  font-size: 14px;
  margin: -27px 0px 0px 140px;
  border: none ;
}
.dropdown-content {
  display: inline-block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  min-width: 150px;
  box-shadow: none;
  padding: 5px 0;
  z-index: 0;
 border: transparent ;
}

.css-1lymaxv-MuiDataGrid-root, .css-1lymaxv-MuiDataGrid-root .MuiDataGrid-withBorderColor{
  border-color: #F3F4F6 !important;
}
.nav-tabs{
  border-bottom: 0px !important;
}
.nav-tabs .nav-item{
  height: 60px;
}
.nav-tabs .nav-item button{
  height: 60px;
  font-size: 14px !important;
  font-weight: 600 !important;
 color:  #504e4e !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover, .nav-tabs .nav-link.active{
  height: 60px;
  color: #FE9900!important;
  border: 0px;
  border-bottom: 1px #FE9900 solid !important;
}

.page-header{
  position: fixed;
  width: 100%;
  top: 70px;
  background-color: #ffffff;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  right: 0px;
  padding-left: 70px;
}
.dashboard-layout .page-header .filter .input-group{
  width: 90px;
}
.dashboard-layout .page-header .input-group-text{
  padding: 0.375rem .5rem !important;
}
.dashboard-layout .page-header .form-select{
  /* padding: 0.375rem 2.25rem 0.375rem 0.2rem;
  width: 60px; */
  
}